import { TonClient } from "@ton/ton";

/**
 * Hook to initialize TonClient.
 *
 * @returns A promise that resolves with the initialized TonClient. The promise is
 * memoized and will only be resolved once.
 *
 * @example
 * const { data: tonClient } = useTonClient();
 * // use tonClient
 */
export function useTonClient(isMainnet = true) {
  if (isMainnet) {
    return new TonClient({
      endpoint: "https://toncenter.com/api/v2/jsonRPC",
    });
  }

  return new TonClient({
    endpoint: "https://testnet.toncenter.com/api/v2/jsonRPC",
  });
}
