import { useEffect, useMemo, useState, type FC } from "react";

import {
  CHAIN,
  SendTransactionRequest,
  TonConnectButton,
  useTonConnectUI,
} from "@tonconnect/ui-react";

import { MiniPay } from "@/contracts/tact_MiniPay";

import {
  MiniPayRouter,
  storeInitMiniPay,
} from "@/contracts/tact_MiniPayRouter";

import { address, beginCell, toNano } from "@ton/core";
import { useTonClient } from "@/hooks/useTonClient";

const premiumPrice = toNano("0.5");

const paymentPool = address("0QBieoHLYQv4K5hxxsLQz0FIhRaHcT4u4u4qp5sqDiMtGdQ-");

const onPaidCallback = () => {};
const useMiniPay = (
  orderId: string,
  amount: bigint,
  { autoUpdate = true, onPaid = onPaidCallback, isMainnet = true } = {}
) => {
  const [isPaid, setIsPaid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateId, setUpdateId] = useState(0);
  const tonClient = useTonClient(isMainnet);
  const [tonConnect] = useTonConnectUI();

  async function checkIfPaid() {
    if (tonClient === undefined) {
      return false;
    }

    const miniPayRouter = await MiniPayRouter.fromInit();

    const miniPay = tonClient.open(
      await MiniPay.fromInit(
        miniPayRouter.address,
        paymentPool,
        orderId,
        amount
      )
    );

    try {
      const data = await miniPay.getData();
      return data.is_paid;
    } catch (e) {
      // not deployed
      return false;
    }
  }

  async function pay() {
    //if (!tonClient) return alert("TonClient is not initialized");

    if (await checkIfPaid()) {
      alert("You have already paid. Please wait for confirmation.");
      return;
    }
    const miniPayRouter = await MiniPayRouter.fromInit();

    const tx: SendTransactionRequest = {
      validUntil: Math.floor(Date.now() / 1000) + 10 * 60,
      from: tonConnect.account?.address,
      network: isMainnet ? CHAIN.MAINNET : CHAIN.TESTNET,
      messages: [
        {
          address: miniPayRouter.address.toRawString(),
          amount: premiumPrice.toString(),
          payload: beginCell()
            .store(
              storeInitMiniPay({
                $$type: "InitMiniPay",
                seller_address: paymentPool,
                order_id: orderId,
                amount: amount,
              })
            )
            .endCell()
            .toBoc()
            .toString("base64"),
        },
      ],
    };

    await tonConnect.sendTransaction(tx);
  }

  async function initPay() {
    if (!tonConnect.account) {
      await tonConnect.openModal();
    }
    await pay();
  }

  useEffect(() => {
    if (isPaid) return; // can't be unpaid
    checkIfPaid().then((isPaid) => {
      setIsPaid(isPaid);
      setIsLoading(false);
      if (isPaid) onPaid();
    });
  }, [orderId, amount, updateId]);

  useEffect(() => {
    if (!autoUpdate) return;

    const timer = setInterval(() => {
      setUpdateId(Math.random());
    }, 10000);

    return () => clearInterval(timer);
  }, [autoUpdate]);

  return { isPaid, isLoading, initPay };
};

export const PremiumPage: FC = () => {
  const [isMainnet, setIsMainnet] = useState(true);
  function onPaid() {
    alert("Paid successfully");
  }

  const id = useMemo(() => {
    return "id-" + Date.now();
  }, []);

  const { isPaid, isLoading, initPay } = useMiniPay(id, premiumPrice, {
    autoUpdate: true,
    onPaid: onPaid,
    isMainnet: isMainnet,
  });

  return (
    <div className="h-full w-full flex flex-col items-center gap-4 justify-center">
      <TonConnectButton />

      <label className="flex items-center gap-2">
        <input
          type="checkbox"
          checked={isMainnet}
          onChange={(e) => setIsMainnet(e.target.checked)}
        />
        Mainnet
      </label>

      <button
        onClick={initPay}
        className="w-32 h-12 bg-white text-black rounded-lg flex items-center justify-center"
      >
        {isLoading ? "Loading..." : isPaid ? "Paid" : "Pay"}
      </button>
    </div>
  );
};
