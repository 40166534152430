import { 
    Cell,
    Slice, 
    Address, 
    Builder, 
    beginCell, 
    ComputeError, 
    TupleItem, 
    TupleReader, 
    Dictionary, 
    contractAddress, 
    ContractProvider, 
    Sender, 
    Contract, 
    ContractABI, 
    ABIType,
    ABIGetter,
    ABIReceiver,
    TupleBuilder,
    DictionaryValue
} from '@ton/core';

export type StateInit = {
    $$type: 'StateInit';
    code: Cell;
    data: Cell;
}

export function storeStateInit(src: StateInit) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeRef(src.code);
        b_0.storeRef(src.data);
    };
}

export function loadStateInit(slice: Slice) {
    let sc_0 = slice;
    let _code = sc_0.loadRef();
    let _data = sc_0.loadRef();
    return { $$type: 'StateInit' as const, code: _code, data: _data };
}

function loadTupleStateInit(source: TupleReader) {
    let _code = source.readCell();
    let _data = source.readCell();
    return { $$type: 'StateInit' as const, code: _code, data: _data };
}

function loadGetterTupleStateInit(source: TupleReader) {
    let _code = source.readCell();
    let _data = source.readCell();
    return { $$type: 'StateInit' as const, code: _code, data: _data };
}

function storeTupleStateInit(source: StateInit) {
    let builder = new TupleBuilder();
    builder.writeCell(source.code);
    builder.writeCell(source.data);
    return builder.build();
}

function dictValueParserStateInit(): DictionaryValue<StateInit> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeStateInit(src)).endCell());
        },
        parse: (src) => {
            return loadStateInit(src.loadRef().beginParse());
        }
    }
}

export type StdAddress = {
    $$type: 'StdAddress';
    workchain: bigint;
    address: bigint;
}

export function storeStdAddress(src: StdAddress) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeInt(src.workchain, 8);
        b_0.storeUint(src.address, 256);
    };
}

export function loadStdAddress(slice: Slice) {
    let sc_0 = slice;
    let _workchain = sc_0.loadIntBig(8);
    let _address = sc_0.loadUintBig(256);
    return { $$type: 'StdAddress' as const, workchain: _workchain, address: _address };
}

function loadTupleStdAddress(source: TupleReader) {
    let _workchain = source.readBigNumber();
    let _address = source.readBigNumber();
    return { $$type: 'StdAddress' as const, workchain: _workchain, address: _address };
}

function loadGetterTupleStdAddress(source: TupleReader) {
    let _workchain = source.readBigNumber();
    let _address = source.readBigNumber();
    return { $$type: 'StdAddress' as const, workchain: _workchain, address: _address };
}

function storeTupleStdAddress(source: StdAddress) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.workchain);
    builder.writeNumber(source.address);
    return builder.build();
}

function dictValueParserStdAddress(): DictionaryValue<StdAddress> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeStdAddress(src)).endCell());
        },
        parse: (src) => {
            return loadStdAddress(src.loadRef().beginParse());
        }
    }
}

export type VarAddress = {
    $$type: 'VarAddress';
    workchain: bigint;
    address: Slice;
}

export function storeVarAddress(src: VarAddress) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeInt(src.workchain, 32);
        b_0.storeRef(src.address.asCell());
    };
}

export function loadVarAddress(slice: Slice) {
    let sc_0 = slice;
    let _workchain = sc_0.loadIntBig(32);
    let _address = sc_0.loadRef().asSlice();
    return { $$type: 'VarAddress' as const, workchain: _workchain, address: _address };
}

function loadTupleVarAddress(source: TupleReader) {
    let _workchain = source.readBigNumber();
    let _address = source.readCell().asSlice();
    return { $$type: 'VarAddress' as const, workchain: _workchain, address: _address };
}

function loadGetterTupleVarAddress(source: TupleReader) {
    let _workchain = source.readBigNumber();
    let _address = source.readCell().asSlice();
    return { $$type: 'VarAddress' as const, workchain: _workchain, address: _address };
}

function storeTupleVarAddress(source: VarAddress) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.workchain);
    builder.writeSlice(source.address.asCell());
    return builder.build();
}

function dictValueParserVarAddress(): DictionaryValue<VarAddress> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeVarAddress(src)).endCell());
        },
        parse: (src) => {
            return loadVarAddress(src.loadRef().beginParse());
        }
    }
}

export type Context = {
    $$type: 'Context';
    bounced: boolean;
    sender: Address;
    value: bigint;
    raw: Slice;
}

export function storeContext(src: Context) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeBit(src.bounced);
        b_0.storeAddress(src.sender);
        b_0.storeInt(src.value, 257);
        b_0.storeRef(src.raw.asCell());
    };
}

export function loadContext(slice: Slice) {
    let sc_0 = slice;
    let _bounced = sc_0.loadBit();
    let _sender = sc_0.loadAddress();
    let _value = sc_0.loadIntBig(257);
    let _raw = sc_0.loadRef().asSlice();
    return { $$type: 'Context' as const, bounced: _bounced, sender: _sender, value: _value, raw: _raw };
}

function loadTupleContext(source: TupleReader) {
    let _bounced = source.readBoolean();
    let _sender = source.readAddress();
    let _value = source.readBigNumber();
    let _raw = source.readCell().asSlice();
    return { $$type: 'Context' as const, bounced: _bounced, sender: _sender, value: _value, raw: _raw };
}

function loadGetterTupleContext(source: TupleReader) {
    let _bounced = source.readBoolean();
    let _sender = source.readAddress();
    let _value = source.readBigNumber();
    let _raw = source.readCell().asSlice();
    return { $$type: 'Context' as const, bounced: _bounced, sender: _sender, value: _value, raw: _raw };
}

function storeTupleContext(source: Context) {
    let builder = new TupleBuilder();
    builder.writeBoolean(source.bounced);
    builder.writeAddress(source.sender);
    builder.writeNumber(source.value);
    builder.writeSlice(source.raw.asCell());
    return builder.build();
}

function dictValueParserContext(): DictionaryValue<Context> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeContext(src)).endCell());
        },
        parse: (src) => {
            return loadContext(src.loadRef().beginParse());
        }
    }
}

export type SendParameters = {
    $$type: 'SendParameters';
    bounce: boolean;
    to: Address;
    value: bigint;
    mode: bigint;
    body: Cell | null;
    code: Cell | null;
    data: Cell | null;
}

export function storeSendParameters(src: SendParameters) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeBit(src.bounce);
        b_0.storeAddress(src.to);
        b_0.storeInt(src.value, 257);
        b_0.storeInt(src.mode, 257);
        if (src.body !== null && src.body !== undefined) { b_0.storeBit(true).storeRef(src.body); } else { b_0.storeBit(false); }
        if (src.code !== null && src.code !== undefined) { b_0.storeBit(true).storeRef(src.code); } else { b_0.storeBit(false); }
        if (src.data !== null && src.data !== undefined) { b_0.storeBit(true).storeRef(src.data); } else { b_0.storeBit(false); }
    };
}

export function loadSendParameters(slice: Slice) {
    let sc_0 = slice;
    let _bounce = sc_0.loadBit();
    let _to = sc_0.loadAddress();
    let _value = sc_0.loadIntBig(257);
    let _mode = sc_0.loadIntBig(257);
    let _body = sc_0.loadBit() ? sc_0.loadRef() : null;
    let _code = sc_0.loadBit() ? sc_0.loadRef() : null;
    let _data = sc_0.loadBit() ? sc_0.loadRef() : null;
    return { $$type: 'SendParameters' as const, bounce: _bounce, to: _to, value: _value, mode: _mode, body: _body, code: _code, data: _data };
}

function loadTupleSendParameters(source: TupleReader) {
    let _bounce = source.readBoolean();
    let _to = source.readAddress();
    let _value = source.readBigNumber();
    let _mode = source.readBigNumber();
    let _body = source.readCellOpt();
    let _code = source.readCellOpt();
    let _data = source.readCellOpt();
    return { $$type: 'SendParameters' as const, bounce: _bounce, to: _to, value: _value, mode: _mode, body: _body, code: _code, data: _data };
}

function loadGetterTupleSendParameters(source: TupleReader) {
    let _bounce = source.readBoolean();
    let _to = source.readAddress();
    let _value = source.readBigNumber();
    let _mode = source.readBigNumber();
    let _body = source.readCellOpt();
    let _code = source.readCellOpt();
    let _data = source.readCellOpt();
    return { $$type: 'SendParameters' as const, bounce: _bounce, to: _to, value: _value, mode: _mode, body: _body, code: _code, data: _data };
}

function storeTupleSendParameters(source: SendParameters) {
    let builder = new TupleBuilder();
    builder.writeBoolean(source.bounce);
    builder.writeAddress(source.to);
    builder.writeNumber(source.value);
    builder.writeNumber(source.mode);
    builder.writeCell(source.body);
    builder.writeCell(source.code);
    builder.writeCell(source.data);
    return builder.build();
}

function dictValueParserSendParameters(): DictionaryValue<SendParameters> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeSendParameters(src)).endCell());
        },
        parse: (src) => {
            return loadSendParameters(src.loadRef().beginParse());
        }
    }
}

export type MiniPay$Data = {
    $$type: 'MiniPay$Data';
    is_paid: boolean;
    router_address: Address;
    seller_address: Address;
    order_id: string;
    amount: bigint;
}

export function storeMiniPay$Data(src: MiniPay$Data) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeBit(src.is_paid);
        b_0.storeAddress(src.router_address);
        b_0.storeAddress(src.seller_address);
        b_0.storeStringRefTail(src.order_id);
        b_0.storeCoins(src.amount);
    };
}

export function loadMiniPay$Data(slice: Slice) {
    let sc_0 = slice;
    let _is_paid = sc_0.loadBit();
    let _router_address = sc_0.loadAddress();
    let _seller_address = sc_0.loadAddress();
    let _order_id = sc_0.loadStringRefTail();
    let _amount = sc_0.loadCoins();
    return { $$type: 'MiniPay$Data' as const, is_paid: _is_paid, router_address: _router_address, seller_address: _seller_address, order_id: _order_id, amount: _amount };
}

function loadTupleMiniPay$Data(source: TupleReader) {
    let _is_paid = source.readBoolean();
    let _router_address = source.readAddress();
    let _seller_address = source.readAddress();
    let _order_id = source.readString();
    let _amount = source.readBigNumber();
    return { $$type: 'MiniPay$Data' as const, is_paid: _is_paid, router_address: _router_address, seller_address: _seller_address, order_id: _order_id, amount: _amount };
}

function loadGetterTupleMiniPay$Data(source: TupleReader) {
    let _is_paid = source.readBoolean();
    let _router_address = source.readAddress();
    let _seller_address = source.readAddress();
    let _order_id = source.readString();
    let _amount = source.readBigNumber();
    return { $$type: 'MiniPay$Data' as const, is_paid: _is_paid, router_address: _router_address, seller_address: _seller_address, order_id: _order_id, amount: _amount };
}

function storeTupleMiniPay$Data(source: MiniPay$Data) {
    let builder = new TupleBuilder();
    builder.writeBoolean(source.is_paid);
    builder.writeAddress(source.router_address);
    builder.writeAddress(source.seller_address);
    builder.writeString(source.order_id);
    builder.writeNumber(source.amount);
    return builder.build();
}

function dictValueParserMiniPay$Data(): DictionaryValue<MiniPay$Data> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeMiniPay$Data(src)).endCell());
        },
        parse: (src) => {
            return loadMiniPay$Data(src.loadRef().beginParse());
        }
    }
}

export type MiniPayData = {
    $$type: 'MiniPayData';
    order_id: string;
    seller_address: Address;
    amount: bigint;
    is_paid: boolean;
}

export function storeMiniPayData(src: MiniPayData) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(2105217444, 32);
        b_0.storeStringRefTail(src.order_id);
        b_0.storeAddress(src.seller_address);
        b_0.storeCoins(src.amount);
        b_0.storeBit(src.is_paid);
    };
}

export function loadMiniPayData(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 2105217444) { throw Error('Invalid prefix'); }
    let _order_id = sc_0.loadStringRefTail();
    let _seller_address = sc_0.loadAddress();
    let _amount = sc_0.loadCoins();
    let _is_paid = sc_0.loadBit();
    return { $$type: 'MiniPayData' as const, order_id: _order_id, seller_address: _seller_address, amount: _amount, is_paid: _is_paid };
}

function loadTupleMiniPayData(source: TupleReader) {
    let _order_id = source.readString();
    let _seller_address = source.readAddress();
    let _amount = source.readBigNumber();
    let _is_paid = source.readBoolean();
    return { $$type: 'MiniPayData' as const, order_id: _order_id, seller_address: _seller_address, amount: _amount, is_paid: _is_paid };
}

function loadGetterTupleMiniPayData(source: TupleReader) {
    let _order_id = source.readString();
    let _seller_address = source.readAddress();
    let _amount = source.readBigNumber();
    let _is_paid = source.readBoolean();
    return { $$type: 'MiniPayData' as const, order_id: _order_id, seller_address: _seller_address, amount: _amount, is_paid: _is_paid };
}

function storeTupleMiniPayData(source: MiniPayData) {
    let builder = new TupleBuilder();
    builder.writeString(source.order_id);
    builder.writeAddress(source.seller_address);
    builder.writeNumber(source.amount);
    builder.writeBoolean(source.is_paid);
    return builder.build();
}

function dictValueParserMiniPayData(): DictionaryValue<MiniPayData> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeMiniPayData(src)).endCell());
        },
        parse: (src) => {
            return loadMiniPayData(src.loadRef().beginParse());
        }
    }
}

export type InitMiniPay = {
    $$type: 'InitMiniPay';
    seller_address: Address;
    order_id: string;
    amount: bigint;
}

export function storeInitMiniPay(src: InitMiniPay) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(3602195586, 32);
        b_0.storeAddress(src.seller_address);
        b_0.storeStringRefTail(src.order_id);
        b_0.storeCoins(src.amount);
    };
}

export function loadInitMiniPay(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 3602195586) { throw Error('Invalid prefix'); }
    let _seller_address = sc_0.loadAddress();
    let _order_id = sc_0.loadStringRefTail();
    let _amount = sc_0.loadCoins();
    return { $$type: 'InitMiniPay' as const, seller_address: _seller_address, order_id: _order_id, amount: _amount };
}

function loadTupleInitMiniPay(source: TupleReader) {
    let _seller_address = source.readAddress();
    let _order_id = source.readString();
    let _amount = source.readBigNumber();
    return { $$type: 'InitMiniPay' as const, seller_address: _seller_address, order_id: _order_id, amount: _amount };
}

function loadGetterTupleInitMiniPay(source: TupleReader) {
    let _seller_address = source.readAddress();
    let _order_id = source.readString();
    let _amount = source.readBigNumber();
    return { $$type: 'InitMiniPay' as const, seller_address: _seller_address, order_id: _order_id, amount: _amount };
}

function storeTupleInitMiniPay(source: InitMiniPay) {
    let builder = new TupleBuilder();
    builder.writeAddress(source.seller_address);
    builder.writeString(source.order_id);
    builder.writeNumber(source.amount);
    return builder.build();
}

function dictValueParserInitMiniPay(): DictionaryValue<InitMiniPay> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeInitMiniPay(src)).endCell());
        },
        parse: (src) => {
            return loadInitMiniPay(src.loadRef().beginParse());
        }
    }
}

export type ForwardPay = {
    $$type: 'ForwardPay';
    buyer_address: Address;
}

export function storeForwardPay(src: ForwardPay) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(3010356634, 32);
        b_0.storeAddress(src.buyer_address);
    };
}

export function loadForwardPay(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 3010356634) { throw Error('Invalid prefix'); }
    let _buyer_address = sc_0.loadAddress();
    return { $$type: 'ForwardPay' as const, buyer_address: _buyer_address };
}

function loadTupleForwardPay(source: TupleReader) {
    let _buyer_address = source.readAddress();
    return { $$type: 'ForwardPay' as const, buyer_address: _buyer_address };
}

function loadGetterTupleForwardPay(source: TupleReader) {
    let _buyer_address = source.readAddress();
    return { $$type: 'ForwardPay' as const, buyer_address: _buyer_address };
}

function storeTupleForwardPay(source: ForwardPay) {
    let builder = new TupleBuilder();
    builder.writeAddress(source.buyer_address);
    return builder.build();
}

function dictValueParserForwardPay(): DictionaryValue<ForwardPay> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeForwardPay(src)).endCell());
        },
        parse: (src) => {
            return loadForwardPay(src.loadRef().beginParse());
        }
    }
}

export type PayError = {
    $$type: 'PayError';
    code: bigint;
}

export function storePayError(src: PayError) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(1406401189, 32);
        b_0.storeInt(src.code, 257);
    };
}

export function loadPayError(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 1406401189) { throw Error('Invalid prefix'); }
    let _code = sc_0.loadIntBig(257);
    return { $$type: 'PayError' as const, code: _code };
}

function loadTuplePayError(source: TupleReader) {
    let _code = source.readBigNumber();
    return { $$type: 'PayError' as const, code: _code };
}

function loadGetterTuplePayError(source: TupleReader) {
    let _code = source.readBigNumber();
    return { $$type: 'PayError' as const, code: _code };
}

function storeTuplePayError(source: PayError) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.code);
    return builder.build();
}

function dictValueParserPayError(): DictionaryValue<PayError> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storePayError(src)).endCell());
        },
        parse: (src) => {
            return loadPayError(src.loadRef().beginParse());
        }
    }
}

export type Deploy = {
    $$type: 'Deploy';
    queryId: bigint;
}

export function storeDeploy(src: Deploy) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(2490013878, 32);
        b_0.storeUint(src.queryId, 64);
    };
}

export function loadDeploy(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 2490013878) { throw Error('Invalid prefix'); }
    let _queryId = sc_0.loadUintBig(64);
    return { $$type: 'Deploy' as const, queryId: _queryId };
}

function loadTupleDeploy(source: TupleReader) {
    let _queryId = source.readBigNumber();
    return { $$type: 'Deploy' as const, queryId: _queryId };
}

function loadGetterTupleDeploy(source: TupleReader) {
    let _queryId = source.readBigNumber();
    return { $$type: 'Deploy' as const, queryId: _queryId };
}

function storeTupleDeploy(source: Deploy) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.queryId);
    return builder.build();
}

function dictValueParserDeploy(): DictionaryValue<Deploy> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeDeploy(src)).endCell());
        },
        parse: (src) => {
            return loadDeploy(src.loadRef().beginParse());
        }
    }
}

export type DeployOk = {
    $$type: 'DeployOk';
    queryId: bigint;
}

export function storeDeployOk(src: DeployOk) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(2952335191, 32);
        b_0.storeUint(src.queryId, 64);
    };
}

export function loadDeployOk(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 2952335191) { throw Error('Invalid prefix'); }
    let _queryId = sc_0.loadUintBig(64);
    return { $$type: 'DeployOk' as const, queryId: _queryId };
}

function loadTupleDeployOk(source: TupleReader) {
    let _queryId = source.readBigNumber();
    return { $$type: 'DeployOk' as const, queryId: _queryId };
}

function loadGetterTupleDeployOk(source: TupleReader) {
    let _queryId = source.readBigNumber();
    return { $$type: 'DeployOk' as const, queryId: _queryId };
}

function storeTupleDeployOk(source: DeployOk) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.queryId);
    return builder.build();
}

function dictValueParserDeployOk(): DictionaryValue<DeployOk> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeDeployOk(src)).endCell());
        },
        parse: (src) => {
            return loadDeployOk(src.loadRef().beginParse());
        }
    }
}

export type FactoryDeploy = {
    $$type: 'FactoryDeploy';
    queryId: bigint;
    cashback: Address;
}

export function storeFactoryDeploy(src: FactoryDeploy) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(1829761339, 32);
        b_0.storeUint(src.queryId, 64);
        b_0.storeAddress(src.cashback);
    };
}

export function loadFactoryDeploy(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 1829761339) { throw Error('Invalid prefix'); }
    let _queryId = sc_0.loadUintBig(64);
    let _cashback = sc_0.loadAddress();
    return { $$type: 'FactoryDeploy' as const, queryId: _queryId, cashback: _cashback };
}

function loadTupleFactoryDeploy(source: TupleReader) {
    let _queryId = source.readBigNumber();
    let _cashback = source.readAddress();
    return { $$type: 'FactoryDeploy' as const, queryId: _queryId, cashback: _cashback };
}

function loadGetterTupleFactoryDeploy(source: TupleReader) {
    let _queryId = source.readBigNumber();
    let _cashback = source.readAddress();
    return { $$type: 'FactoryDeploy' as const, queryId: _queryId, cashback: _cashback };
}

function storeTupleFactoryDeploy(source: FactoryDeploy) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.queryId);
    builder.writeAddress(source.cashback);
    return builder.build();
}

function dictValueParserFactoryDeploy(): DictionaryValue<FactoryDeploy> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeFactoryDeploy(src)).endCell());
        },
        parse: (src) => {
            return loadFactoryDeploy(src.loadRef().beginParse());
        }
    }
}

export type MiniPayRouter$Data = {
    $$type: 'MiniPayRouter$Data';
}

export function storeMiniPayRouter$Data(src: MiniPayRouter$Data) {
    return (builder: Builder) => {
        let b_0 = builder;
    };
}

export function loadMiniPayRouter$Data(slice: Slice) {
    let sc_0 = slice;
    return { $$type: 'MiniPayRouter$Data' as const };
}

function loadTupleMiniPayRouter$Data(source: TupleReader) {
    return { $$type: 'MiniPayRouter$Data' as const };
}

function loadGetterTupleMiniPayRouter$Data(source: TupleReader) {
    return { $$type: 'MiniPayRouter$Data' as const };
}

function storeTupleMiniPayRouter$Data(source: MiniPayRouter$Data) {
    let builder = new TupleBuilder();
    return builder.build();
}

function dictValueParserMiniPayRouter$Data(): DictionaryValue<MiniPayRouter$Data> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeMiniPayRouter$Data(src)).endCell());
        },
        parse: (src) => {
            return loadMiniPayRouter$Data(src.loadRef().beginParse());
        }
    }
}

 type MiniPay_init_args = {
    $$type: 'MiniPay_init_args';
    router_address: Address;
    seller_address: Address;
    order_id: string;
    amount: bigint;
}

function initMiniPay_init_args(src: MiniPay_init_args) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeAddress(src.router_address);
        b_0.storeAddress(src.seller_address);
        b_0.storeStringRefTail(src.order_id);
        b_0.storeInt(src.amount, 257);
    };
}

async function MiniPay_init(router_address: Address, seller_address: Address, order_id: string, amount: bigint) {
    const __code = Cell.fromBase64('te6ccgECEgEAA9MAART/APSkE/S88sgLAQIBYgIDA3rQAdDTAwFxsKMB+kABINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiFRQUwNvBPhhAvhi2zxVFNs88uCCDgQFAgFYDA0CtO2i7fsBkjB/4HAh10nCH5UwINcLH94gghCzbmWauo6yMNMfAYIQs25lmrry4IH6QAEg10mBAQu68uCIINcLCiCBBP+68tCJgwm68uCIMYrbBn/gwACRMOMNcAYHALTI+EMBzH8BygBVQFBFygBYINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiM8WASDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IjPFshYzxbJAcwB+gLJ7VQD/O2i7fv4QW8kMDImgUvJAscF8vQWFRRDMNs8VHVDVHVJ7UTtRe1HjrkxyAGCEFPT+qVYyx+BAQHPAMnIgljAAAAAAAAAAAAAAAABActnzMlw+wBwcIBAQzBtbW3bPDDbMTTtZ+1l7WR2f+0RjhGBaRUhwv/y9IErEgbAABby9AgKCQGc+QGC8NMDTMhXXjh4Y9YcP66U4t4+8sbngCO76LDaUo1yHQ4iuo6m+EFvJBAjXwMjggCzNALHBfL0+CdvEFIwcIMGQzBtbW3bPDB/2zHgCgAs+CdvECGhggiYloBmtgihggiYloCgoQJi7UHt8QHy/39TFbYIUWahUkdwWXBtbW3bPDAlwgCOjFBlcHBDMG1tbds8MJI1NeJVAwoKAcrIcQHKAVAHAcoAcAHKAlAFINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiM8WUAP6AnABymgjbrORf5MkbrPilzMzAXABygDjDSFus5x/AcoAASBu8tCAAcyVMXABygDiyQH7CAsAmH8BygDIcAHKAHABygAkbrOdfwHKAAQgbvLQgFAEzJY0A3ABygDiJG6znX8BygAEIG7y0IBQBMyWNANwAcoA4nABygACfwHKAALJWMwCEbh2LbPNs8bFSA4PABG4K+7UTQ0gABgByO1E0NQB+GPSAAGOTNIA+kABINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiAH6QAEg10mBAQu68uCIINcLCiCBBP+68tCJgwm68uCIAdQB0AH6AFVAbBXg+CjXCwqDCbry4IkQAAhUcSAnAaL6QAEg10mBAQu68uCIINcLCiCBBP+68tCJgwm68uCIAfpAASDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IgB1AHQAYEBAdcAVTAE0VUC2zwRAByBRoz4QlJQxwXy9HBVMA==');
    const __system = Cell.fromBase64('te6cckECFAEAA90AAQHAAQEFoatNAgEU/wD0pBP0vPLICwMCAWIEDQN60AHQ0wMBcbCjAfpAASDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IhUUFMDbwT4YQL4Yts8VRTbPPLggg8FDAK07aLt+wGSMH/gcCHXScIflTAg1wsf3iCCELNuZZq6jrIw0x8BghCzbmWauvLggfpAASDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IgxitsGf+DAAJEw4w1wBgkD/O2i7fv4QW8kMDImgUvJAscF8vQWFRRDMNs8VHVDVHVJ7UTtRe1HjrkxyAGCEFPT+qVYyx+BAQHPAMnIgljAAAAAAAAAAAAAAAABActnzMlw+wBwcIBAQzBtbW3bPDDbMTTtZ+1l7WR2f+0RjhGBaRUhwv/y9IErEgbAABby9AcKCAAs+CdvECGhggiYloBmtgihggiYloCgoQJi7UHt8QHy/39TFbYIUWahUkdwWXBtbW3bPDAlwgCOjFBlcHBDMG1tbds8MJI1NeJVAwoKAZz5AYLw0wNMyFdeOHhj1hw/rpTi3j7yxueAI7vosNpSjXIdDiK6jqb4QW8kECNfAyOCALM0AscF8vT4J28QUjBwgwZDMG1tbds8MH/bMeAKAcrIcQHKAVAHAcoAcAHKAlAFINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiM8WUAP6AnABymgjbrORf5MkbrPilzMzAXABygDjDSFus5x/AcoAASBu8tCAAcyVMXABygDiyQH7CAsAmH8BygDIcAHKAHABygAkbrOdfwHKAAQgbvLQgFAEzJY0A3ABygDiJG6znX8BygAEIG7y0IBQBMyWNANwAcoA4nABygACfwHKAALJWMwAtMj4QwHMfwHKAFVAUEXKAFgg10mBAQu68uCIINcLCiCBBP+68tCJgwm68uCIzxYBINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiM8WyFjPFskBzAH6AsntVAIBWA4TAhG4di2zzbPGxUgPEgHI7UTQ1AH4Y9IAAY5M0gD6QAEg10mBAQu68uCIINcLCiCBBP+68tCJgwm68uCIAfpAASDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IgB1AHQAfoAVUBsFeD4KNcLCoMJuvLgiRABovpAASDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IgB+kABINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiAHUAdABgQEB1wBVMATRVQLbPBEAHIFGjPhCUlDHBfL0cFUwAAhUcSAnABG4K+7UTQ0gABjEaEE1');
    let builder = beginCell();
    builder.storeRef(__system);
    builder.storeUint(0, 1);
    initMiniPay_init_args({ $$type: 'MiniPay_init_args', router_address, seller_address, order_id, amount })(builder);
    const __data = builder.endCell();
    return { code: __code, data: __data };
}

const MiniPay_errors: { [key: number]: { message: string } } = {
    2: { message: `Stack underflow` },
    3: { message: `Stack overflow` },
    4: { message: `Integer overflow` },
    5: { message: `Integer out of expected range` },
    6: { message: `Invalid opcode` },
    7: { message: `Type check error` },
    8: { message: `Cell overflow` },
    9: { message: `Cell underflow` },
    10: { message: `Dictionary error` },
    11: { message: `'Unknown' error` },
    12: { message: `Fatal error` },
    13: { message: `Out of gas error` },
    14: { message: `Virtualization error` },
    32: { message: `Action list is invalid` },
    33: { message: `Action list is too long` },
    34: { message: `Action is invalid or not supported` },
    35: { message: `Invalid source address in outbound message` },
    36: { message: `Invalid destination address in outbound message` },
    37: { message: `Not enough TON` },
    38: { message: `Not enough extra-currencies` },
    39: { message: `Outbound message does not fit into a cell after rewriting` },
    40: { message: `Cannot process a message` },
    41: { message: `Library reference is null` },
    42: { message: `Library change action error` },
    43: { message: `Exceeded maximum number of cells in the library or the maximum depth of the Merkle tree` },
    50: { message: `Account state size exceeded limits` },
    128: { message: `Null reference exception` },
    129: { message: `Invalid serialization prefix` },
    130: { message: `Invalid incoming message` },
    131: { message: `Constraints error` },
    132: { message: `Access denied` },
    133: { message: `Contract stopped` },
    134: { message: `Invalid argument` },
    135: { message: `Code of a contract was not found` },
    136: { message: `Invalid address` },
    137: { message: `Masterchain support is not enabled for this contract` },
    10831: { message: `Insufficient payment amount` },
    11026: { message: `Already paid` },
    18060: { message: `not from router` },
    19401: { message: `Unauthorized forwardPay attempt` },
    26901: { message: `Value doesn't cover gas and storage fees` },
    45876: { message: `Unauthorized flush attempt` },
}

const MiniPay_types: ABIType[] = [
    {"name":"StateInit","header":null,"fields":[{"name":"code","type":{"kind":"simple","type":"cell","optional":false}},{"name":"data","type":{"kind":"simple","type":"cell","optional":false}}]},
    {"name":"StdAddress","header":null,"fields":[{"name":"workchain","type":{"kind":"simple","type":"int","optional":false,"format":8}},{"name":"address","type":{"kind":"simple","type":"uint","optional":false,"format":256}}]},
    {"name":"VarAddress","header":null,"fields":[{"name":"workchain","type":{"kind":"simple","type":"int","optional":false,"format":32}},{"name":"address","type":{"kind":"simple","type":"slice","optional":false}}]},
    {"name":"Context","header":null,"fields":[{"name":"bounced","type":{"kind":"simple","type":"bool","optional":false}},{"name":"sender","type":{"kind":"simple","type":"address","optional":false}},{"name":"value","type":{"kind":"simple","type":"int","optional":false,"format":257}},{"name":"raw","type":{"kind":"simple","type":"slice","optional":false}}]},
    {"name":"SendParameters","header":null,"fields":[{"name":"bounce","type":{"kind":"simple","type":"bool","optional":false}},{"name":"to","type":{"kind":"simple","type":"address","optional":false}},{"name":"value","type":{"kind":"simple","type":"int","optional":false,"format":257}},{"name":"mode","type":{"kind":"simple","type":"int","optional":false,"format":257}},{"name":"body","type":{"kind":"simple","type":"cell","optional":true}},{"name":"code","type":{"kind":"simple","type":"cell","optional":true}},{"name":"data","type":{"kind":"simple","type":"cell","optional":true}}]},
    {"name":"MiniPay$Data","header":null,"fields":[{"name":"is_paid","type":{"kind":"simple","type":"bool","optional":false}},{"name":"router_address","type":{"kind":"simple","type":"address","optional":false}},{"name":"seller_address","type":{"kind":"simple","type":"address","optional":false}},{"name":"order_id","type":{"kind":"simple","type":"string","optional":false}},{"name":"amount","type":{"kind":"simple","type":"uint","optional":false,"format":"coins"}}]},
    {"name":"MiniPayData","header":2105217444,"fields":[{"name":"order_id","type":{"kind":"simple","type":"string","optional":false}},{"name":"seller_address","type":{"kind":"simple","type":"address","optional":false}},{"name":"amount","type":{"kind":"simple","type":"uint","optional":false,"format":"coins"}},{"name":"is_paid","type":{"kind":"simple","type":"bool","optional":false}}]},
    {"name":"InitMiniPay","header":3602195586,"fields":[{"name":"seller_address","type":{"kind":"simple","type":"address","optional":false}},{"name":"order_id","type":{"kind":"simple","type":"string","optional":false}},{"name":"amount","type":{"kind":"simple","type":"uint","optional":false,"format":"coins"}}]},
    {"name":"ForwardPay","header":3010356634,"fields":[{"name":"buyer_address","type":{"kind":"simple","type":"address","optional":false}}]},
    {"name":"PayError","header":1406401189,"fields":[{"name":"code","type":{"kind":"simple","type":"int","optional":false,"format":257}}]},
    {"name":"Deploy","header":2490013878,"fields":[{"name":"queryId","type":{"kind":"simple","type":"uint","optional":false,"format":64}}]},
    {"name":"DeployOk","header":2952335191,"fields":[{"name":"queryId","type":{"kind":"simple","type":"uint","optional":false,"format":64}}]},
    {"name":"FactoryDeploy","header":1829761339,"fields":[{"name":"queryId","type":{"kind":"simple","type":"uint","optional":false,"format":64}},{"name":"cashback","type":{"kind":"simple","type":"address","optional":false}}]},
    {"name":"MiniPayRouter$Data","header":null,"fields":[]},
]

const MiniPay_getters: ABIGetter[] = [
    {"name":"data","arguments":[],"returnType":{"kind":"simple","type":"MiniPayData","optional":false}},
]

export const MiniPay_getterMapping: { [key: string]: string } = {
    'data': 'getData',
}

const MiniPay_receivers: ABIReceiver[] = [
    {"receiver":"internal","message":{"kind":"typed","type":"ForwardPay"}},
    {"receiver":"internal","message":{"kind":"text","text":"flush"}},
]

export class MiniPay implements Contract {
    
    static async init(router_address: Address, seller_address: Address, order_id: string, amount: bigint) {
        return await MiniPay_init(router_address, seller_address, order_id, amount);
    }
    
    static async fromInit(router_address: Address, seller_address: Address, order_id: string, amount: bigint) {
        const init = await MiniPay_init(router_address, seller_address, order_id, amount);
        const address = contractAddress(0, init);
        return new MiniPay(address, init);
    }
    
    static fromAddress(address: Address) {
        return new MiniPay(address);
    }
    
    readonly address: Address; 
    readonly init?: { code: Cell, data: Cell };
    readonly abi: ContractABI = {
        types:  MiniPay_types,
        getters: MiniPay_getters,
        receivers: MiniPay_receivers,
        errors: MiniPay_errors,
    };
    
    private constructor(address: Address, init?: { code: Cell, data: Cell }) {
        this.address = address;
        this.init = init;
    }
    
    async send(provider: ContractProvider, via: Sender, args: { value: bigint, bounce?: boolean| null | undefined }, message: ForwardPay | 'flush') {
        
        let body: Cell | null = null;
        if (message && typeof message === 'object' && !(message instanceof Slice) && message.$$type === 'ForwardPay') {
            body = beginCell().store(storeForwardPay(message)).endCell();
        }
        if (message === 'flush') {
            body = beginCell().storeUint(0, 32).storeStringTail(message).endCell();
        }
        if (body === null) { throw new Error('Invalid message type'); }
        
        await provider.internal(via, { ...args, body: body });
        
    }
    
    async getData(provider: ContractProvider) {
        let builder = new TupleBuilder();
        let source = (await provider.get('data', builder.build())).stack;
        const result = loadGetterTupleMiniPayData(source);
        return result;
    }
    
}