import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { type FC, useMemo } from "react";

import { PremiumPage } from "@/pages/PremiumPage/PremiumPage";

const Inner: FC = () => {
  // check if dev mode or debug mode is enabled

  const manifestUrl = useMemo(() => {
    return new URL(
      "/tonconnect-manifest.json",
      window.location.href
    ).toString();
  }, []);

  return (
    <div className="h-full">
      <TonConnectUIProvider manifestUrl={manifestUrl}>
        <PremiumPage />
      </TonConnectUIProvider>
    </div>
  );
};

export const Root: FC = () => <Inner />;
